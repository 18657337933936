import React from 'react'
import Layout from '../components/Layout'

const termsandconditions = () => {
  return (
    <Layout>
    <div style={{padding: '5vw'}}>
     Terms and Conditions
    </div>
    </Layout>
  )
}

export default termsandconditions
